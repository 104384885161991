import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NumerologyCalculator from "./pages/chaldeanNumerologyChart";
import LoShuGridChart from "./pages/LoShuGridChart";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import About from "./pages/About";
import Privateroute from "./components/Privateroute";
import UserProvider from "./context/UserProvider";
import ProfileInfo from "./pages/user-routes/ProfileInfo";
import IncomeTracker from "./components/IncomeTracker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./app/store";

function App() {
  return (
    // -- DEPLOYING ON PORT 80 WITH VIRTUAL DIRECTORY NAME  basename="/stacklearn"--
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<NumerologyCalculator />} />
          <Route path="/NumerologyCalculator" element={<NumerologyCalculator />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/SignUp" element={<SignUp />} />
          <Route path="/About" element={<About />} />
          <Route path="/user" element={<Privateroute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile-info/:userID" element={<ProfileInfo />} />
          </Route>
        </Routes>
        <Routes>
          <Route path="/IncomeTracker" element={<IncomeTracker />} />
          <Route path="/LoShuGrid" element={<LoShuGridChart />} />
        </Routes>
        <ToastContainer autoClose={2500} />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
