import Base from "../components/Base";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useEffect, useState } from "react";
import { doLogin } from "../auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getloginUser } from "../features/User/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../services/helper";

const Login = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState({
    error: {},
    hasError: false,
  });

  const resetData = () => {
    setLoginDetail({
      username: "",
      password: "",
    });
  };

  useEffect(() => {
    if (user.status === STATUSES.IDLE) {
      doLogin(user.user, () => {
        console.log("Login details added to Local Storage.");
        navigate("/user/dashboard");
      });
      toast.success("User Successfully Loggedin !!");
    }
  }, [user.status]);

  const handleChange = (event, fieldname) => {
    setLoginDetail({ ...loginDetail, [fieldname]: event.target.value });
  };
  const handleformSubmit = (event) => {
    event.preventDefault();
    console.log(loginDetail);
    dispatch(getloginUser(loginDetail));
    //Validation
    //Call apis
  };
  return (
    <Base>
      <Container>
        <Row className="mt-4">
          <Col sm={{ size: 6, offset: 3 }}>
            <Card color="dark" outline>
              <CardHeader>
                <h3>Login Here !!</h3>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleformSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Enter Username here"
                      id="username"
                      onChange={(e) => handleChange(e, "username")}
                      value={loginDetail.username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Enter Password</label>
                    <Input
                      type="password"
                      placeholder="Enter Password here"
                      id="password"
                      onChange={(e) => handleChange(e, "password")}
                      value={loginDetail.password}
                    />
                  </FormGroup>
                  <Container className="text-center">
                    <Button color="primary" outline>
                      Login
                    </Button>
                    <Button
                      color="secondary"
                      className="ms-2"
                      outline
                      onClick={resetData}
                    >
                      Reset
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default Login;
