//isLoggedIn=>

export const isLoggedIn = () => {
  let data = localStorage.getItem("data");
  if (data != null) return true;
  else return false;
};

//doLogin=> data=>set to localstorage

export const doLogin = (data,next) => {
  localStorage.setItem("data", JSON.stringify(data));
  next()
};

function appendToStorage(name, data){
  var oldItems = JSON.parse(localStorage.getItem(name)) || [];

 oldItems.push(data);

 localStorage.setItem(name, JSON.stringify(oldItems));
}

export const addfin= (data) =>{
  appendToStorage("fin", JSON.stringify(data));
};

export const updatefin = (data) =>{
  localStorage.removeItem("fin");
  if(data.length > 0) {
    // console.log("simple fin " , data);
    // console.log("gstringifyet fin " , JSON.stringify(data));
    appendToStorage("fin", data);
  }
}

export const isArray =(obj)=>{
  return !!obj && obj.constructor === Array;
}

export const ischeckString = (e)=>{
  return typeof e === "string" || e instanceof String;
}

export const getfin= () =>{
  var data = [];
  data = JSON.parse(localStorage.getItem("fin"));
  return data;
};
//doLogout=> remove from localStorage

export const doLogout = (next) => {
  localStorage.removeItem("data");
  next()
};

//get currentUser
export const getCurrentUserDetail = () => {
  if (isLoggedIn()) {
    return JSON.parse(localStorage.getItem("data")).userDetails;
  } else {
    return undefined;
  }
};

export const getToken=()=>{
  if(isLoggedIn()){
    return JSON.parse(localStorage.getItem("data")).jwtToken.token
  }else{
    return null;
  }
}

export const isShoonyaLoggedIn = () => {
  let data = localStorage.getItem("ShoonyaToken");
  if (data != null) return true;
  else return false;
};

export const getShoonyaToken = () =>{
  if(isShoonyaLoggedIn()){
    return localStorage.getItem("ShoonyaToken")
  }else{
    return null;
  }
}
