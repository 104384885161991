import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // Importing the info icon
import Modal from 'react-modal';

// Modal custom styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Width of the modal
    maxWidth: '600px', // Max width of the modal
    padding: '20px',
    borderRadius: '10px'
  },
};

const NumerologyInfoModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <button 
        onClick={openModal} 
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          fontSize: '10px'
        }}
      >
        <FaInfoCircle />
      </button>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Chaldean Numerology</h2>
        <div style={{ textAlign: 'left', fontSize: '16px', lineHeight: '1.6' }}>
          <p><strong>#0</strong> symbolizes untapped <strong>#potential</strong>, urging individuals to explore their undiscovered talents.</p>
          <p><strong>#1</strong> empowers <strong>#selfsufficiency</strong> and dominance.</p>
          <p><strong>#2</strong> signifies adaptability and <strong>#versatility</strong>.</p>
          <p><strong>#3</strong> encourages <strong>#creative expression</strong> and embracing new <strong>#opportunities</strong>.</p>
          <p><strong>#4</strong> embodies orderliness and <strong>#discipline</strong>.</p>
          <p><strong>#5</strong> represents <strong>#freedom</strong> and exploration.</p>
          <p><strong>#6</strong> denotes a focus on important matters, such as <strong>#teaching</strong> or consulting.</p>
          <p><strong>#7</strong> reflects intelligence and <strong>#curiosity</strong>.</p>
          <p><strong>#8</strong> embodies <strong>#determination</strong> and ambition.</p>
        </div>
        <button 
          onClick={closeModal} 
          style={{ 
            display: 'block', 
            margin: '10px auto 0', 
            padding: '5px 10px',
            backgroundColor: '#D35400', 
            color: 'white', 
            border: 'none',
            borderRadius: '1px',
            cursor: 'pointer'
          }}
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default NumerologyInfoModal;
