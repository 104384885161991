import React, {  } from "react";
import FinProvider from "../context/FinProvider";
import Header from "../pages/Expense/Header";
import IncomeForm from "../pages/Expense/IncomeForm";
import IncomeList from "../pages/Expense/IncomeList";
import Base from "./Base";
import "./IncomeTracker.css";


const IncomeTracker = () => {
  // const [income, setIncome] = useState([]);

  return (
    <Base>
      <div>
        <FinProvider>
          <Header />
          <IncomeForm/>
          <IncomeList/>
        </FinProvider>
      </div>
    </Base>
  );
};

export default IncomeTracker;
