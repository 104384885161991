import axios from "axios";
import { getToken, getShoonyaToken } from "../auth";

export const BASE_URL =
  "https://api.local.stackskills.com/NextGenStackSkills/api";

export const myAxios = axios.create({
  baseURL: BASE_URL,
});

export const shoonyaaxio = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type ": "application/json",
  },
});

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

export const shoonyaaxioLogin = axios.create({
  baseURL: window.API_URL,
  headers : {
    "Content-Type ": "application/json",
    'x-authorization':  getShoonyaToken() 
  },
});

export const appendToStorage = (name, data) => {
  var oldItems = JSON.parse(localStorage.getItem(name)) || [];

  if (data.length > 0) {
    data.forEach((x) => oldItems.push(x));
  } else {
    oldItems.push(data);
  }

  localStorage.setItem(name, JSON.stringify(oldItems));
};

export const Subscibe_Instruments = window.Subscibe_Instruments;

export const privateAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

shoonyaaxio.interceptors.request.use(
  (config) => {
    const token = getShoonyaToken();

    if (!config.headers) {
      config.headers = {};
    }

    config.headers["authorization"] = `${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

privateAxios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (!config.headers) {
      config.headers = {};
    }
    if (token) {
      config.headers["Authorization"] = `bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const ShoonyaLogin = () => {
  shoonyaaxioLogin
    .get("login")
    .then((response) => {
      localStorage.setItem("ShoonyaToken", response.data?.susertoken);
      return true;
    })
    .catch((error) => {
      console.error("Login failed.", error);
      return false;
    });
};

// shoonyaaxioLogin.interceptors.request.use(
//   (config) => {
//     const token = getShoonyaToken();

//     if (!config.headers) {
//       config.headers = {};
//     }
//     config.headers["x-authorization"] = token;
//     headers: { 'Authorization': 'Bearer ' + accessToken }
//     return config;
//   },
//   (error) => {
//     // if (error.request.status === 400) {
//     //   ShoonyaLogin();
//     // }
//     return Promise.reject(error);
//   }
// );

// shoonyaaxioLogin.interceptors.response.use(async function (response) {
//   return response;
// }, async function (error) {
//   localStorage.removeItem("ShoonyaToken")
//   if (error.response.status === 401) {
//       //call your api for refresh token
//       ShoonyaLogin();
//       return axios(error.config);
//   }else if (error.request.status === 400) {
//     ShoonyaLogin();
//   }
//   return Promise.reject(error);
// });

// privateAxios.interceptors.request.use(
//   function (config) {
//     const token = getToken();
//     if (token) {
//       console.log("start add bearer");
//       config.headers["Authorization"] = token;
//       console.log("end add bearer" + config);
//     }
//     console.log("request config", config);
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );
