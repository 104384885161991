import { Button, Col, Container, Row } from "reactstrap";
import "../../components/IncomeTracker.css";
import React, { useContext } from "react";
import FinContext from "../../context/FinContext";
import { ischeckString } from "../../auth/index";

function IncomeItem({ incomedata, index, removeIncome }) {
  const { income, setincome } = useContext(FinContext);

  // console.log(Object.keys(incomedata)[0]);
  // console.log(incomedata[Object.keys(incomedata)[0]]);
  var data;

  var day;
  var month;
  var year;

  if (incomedata) {
    data = ischeckString(incomedata) ? JSON.parse(incomedata) : incomedata;
    let date = new Date(data.date);
    day = date.getDate();
    month = date.getMonth() + 1;
    year = date.getFullYear();
  }

  const removeHandle = (i) => {
    removeIncome(i);
    // console.log("after removeHandle" + JSON.stringify(finconext.fin));
  };

  return (
    <Container>
      <Row>
        <Col className="bg-light border" sm="4" xs="6">
          {data && data.desc}
        </Col>
        <Col className="bg-light border" sm="3" xs="6">
          ₹ {data && data.price}
        </Col>
        <Col className="bg-light border" sm="3">
          {day + "/" + month + "/" + year}
        </Col>
        <Col className="bg-light border" sm="2">
          <Button
            block
            color="danger"
            outline
            size="sm"
            onClick={() => removeHandle(index)}
          >
            {"Remove "}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default IncomeItem;
