import React, { useState, useEffect, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ischeckString } from "../../auth/index";
import FinContext from "../../context/FinContext";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "Jaune",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenselect, setDropdownOpenselect] = useState(null);
  const [month, setmonth] = useState("Select Month");
  const [totalIncome, setTotalIncome] = useState(0);
  const { income, setincome, tempincome, settempincome } =
    useContext(FinContext);

  useEffect(() => {
    let temp = 0;
    if (income && income.length > 0) {
      var tempincome = ischeckString(income) ? JSON.parse(income) : income;
      for (let i = 0; i < tempincome.length; i++) {
        temp += parseInt(
          ischeckString(tempincome[i])
            ? JSON.parse(tempincome[i]).price
            : tempincome[i].price
        );
      }
    }
    setTotalIncome(temp);
  }, [income]);

  const handleMonths = (value) => {
    console.log(dropdownOpen);
    if (income && income.length > 0 && dropdownOpenselect == null) {
      let datadate = income.map((el) =>
        ischeckString(el) ? JSON.parse(el) : el
      );
      settempincome(datadate);
      setDropdownOpenselect(true);
      handledata(income, value);
    } else {
      handledata(tempincome, value);
      setDropdownOpenselect(true);
    }
  };

  const handledata = (incomed, value) => {
    let datadate = incomed.map((el) =>
      ischeckString(el) ? JSON.parse(el) : el
    );

    var newArray = datadate.filter(function (el) {
      var [year, month] = el.date.split("-"); // Or, var month = e.date.split('-')[1];
      return value === months[month - 1]; //&& (currentYear == year);
    });
    setincome(newArray);
    setmonth(value);
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <header>
      <h1>Income Tracker</h1>
      <div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle caret>{month}</DropdownToggle>
          <DropdownMenu>
            {months.map((value, id) => {
              return (
                <div key={id}>
                  <DropdownItem onClick={() => handleMonths(value)}>
                    {value}
                  </DropdownItem>
                </div>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="total-income">₹ {totalIncome}</div>
    </header>
  );
}

export default Header;
