import { myAxios,privateAxios } from "./helper";

export const signUp = async (user) => {
  const response = await myAxios.post("/user/register", user);
  return response.data;
};

export const loginUser = async (loginDetail) => {
  const response = await myAxios
    .post("/login", loginDetail);
  return response.data;
};

export const getUser = async (userId) => {
  const resp = await privateAxios.get(`/user/GetUserDetails/${userId}`);
  return resp.data;
};
