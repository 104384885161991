import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    selectedIndexCriteria: { index: "BANKNIFTY", lotSize: 25 },
    selectedExpiryCriteria: { expiry: "02FEB23" },
    indexPrice : "",
  },
  reducers: {
    setIndexFilterCriteria: (state, action) => {
      state.selectedIndexCriteria = action.payload;
    },
    setExpiryFilterCriteria: (state, action) => {
      state.selectedExpiryCriteria = action.payload;
    },
    setIndexPrice: (state, action) => {
      state.indexPrice = action.payload;
    },
  },
});

export const {
  setIndexFilterCriteria,
  setExpiryFilterCriteria,
  setIndexPrice,
} = filterSlice.actions;

export default filterSlice.reducer;
