import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getfin, adfin ,isArray} from "../auth";
import FinContext from "./FinContext";

const FinProvider = (prop) => {
  const [income, setincome] = useState([]);
  const [tempincome ,settempincome] = useState([])

  useEffect(() => {
    // console.log("Useeffect " ,isArray(getfin()) ? getfin(): JSON.parse(getfin()));
    setincome(
      getfin()
    );
  }, []);

  return (
    <FinContext.Provider value={{ income, setincome,tempincome,settempincome }}>
      {prop.children}
    </FinContext.Provider>
  );
};

export default FinProvider;
