import React from 'react';

const LoShuGrid = ({ grid }) => {
  // Slightly more vibrant colors with better contrast
  const colors = [
    { background: '#3366FF', text: '#FFFFFF' },  // Vibrant Blue
    { background: '#FF4D4D', text: '#FFFFFF' },  // Vibrant Red
    { background: '#FFFFFF', text: '#333333' },  // Light Gray
    { background: '#FFDD44', text: '#333333' },  // Vibrant Yellow
    { background: '#33CC33', text: '#FFFFFF' },  // Vibrant Green
    { background: '#8C8C8C', text: '#FFFFFF' },  // Medium Gray
    { background: '#333333', text: '#FFFFFF' },  // Dark Gray (Black substitute)
    { background: '#FFB84D', text: '#333333' },  // Vibrant Orange
    { background: '#E6E6E6', text: '#333333' }   // Light Gray (Alternative for white)
  ];

  const labels = [
    'Money and Property',
    'Marriage and Relationship',
    'Health and Family',
    'Fame and Prosperity',
    'Energy and Stability',
    'Children and Creativity',
    'Knowledge and Intuition',
    'Career and Success',
    'Friends and Beginnings'
  ];

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', padding: '20px', maxWidth: '300px', margin: 'auto' }}>
      {grid.map((num, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
            borderRadius: '10px',
            backgroundColor: colors[index].background,
            color: colors[index].text,
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          <div>{num || ''}</div>
          <div style={{ fontSize: '10px', marginTop: '5px' }}>{labels[index]}</div>
        </div>
      ))}
    </div>
  );
};

export default LoShuGrid;
