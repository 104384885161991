import React, { useState } from 'react';
import LoShuGrid from './LoShuGrid';
import Base from "../components/Base";

const getSingleDigitSum = (number) => {
  let sum = number;
  
  // Continue summing the digits until the sum is a single digit
  while (sum > 9) {
    sum = sum.toString().split('').map(Number).reduce((acc, num) => acc + num, 0);
  }
  
  return sum;
};

const calculateKuaNumber = (year, gender) => {
  // Convert the year to a single-digit sum
  const yearSum = getSingleDigitSum(year);
  
  let kuaNumber;
  if (gender === 'male') {
    kuaNumber = 11 - yearSum;
  } else {
    kuaNumber = yearSum + 4;
    if(kuaNumber>9){
      kuaNumber = getSingleDigitSum(kuaNumber);
    }
  }
  
  // Normalize if kuaNumber is more than 9
  kuaNumber = getSingleDigitSum(kuaNumber);
  
  // Special case for kua number 5
  // if (kuaNumber === 5) {
  //   kuaNumber = gender === 'male' ? 2 : 8;
  // }
  
  return kuaNumber;
};

const calculateLoShuGrid = (dob, gender) => {
  const dateNumbers = dob.replace(/-/g, '').split('').map(Number);

  // Calculate Driver Number
  const day = parseInt(dob.slice(8, 10), 10); // Extract day from the DOB
  const driverNumber = day > 9 ? Math.floor(day / 10) + (day % 10) : day;

  // Calculate Life Path Number (Conductor)
  const lifePathNumber = dateNumbers.reduce((acc, num) => acc + num, 0);
  const normalizedLifePath = lifePathNumber > 9 ? Math.floor(lifePathNumber / 10) + (lifePathNumber % 10) : lifePathNumber;

  // Calculate Kua Number
  const year = parseInt(dob.slice(0, 4), 10); // Extract year from DOB
  const kuaNumber = calculateKuaNumber(year, gender);

  // Combine all relevant numbers
  const allNumbers = [...dateNumbers, driverNumber, normalizedLifePath, kuaNumber];
  const loShuGrid = Array(9).fill('');

  // Position Mapping for the Lo Shu Grid
  allNumbers.forEach((num) => {
    const position = [4, 9, 2, 3, 5, 7, 8, 1, 6].indexOf(num);
    if (position !== -1) loShuGrid[position] += num;  // Concatenate repeated numbers
  });

  return { loShuGrid, kuaNumber };
};

const LoShuGridChart = () => {
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('male');
  const [grid, setGrid] = useState(Array(9).fill(''));
  const [kuaNumber, setKuaNumber] = useState(null);

  const handleChangeDOB = (event) => {
    setDob(event.target.value);
    setGrid(Array(9).fill(''));  // Reset grid
    setKuaNumber(null);          // Reset Kua number
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
    setGrid(Array(9).fill(''));  // Reset grid
    setKuaNumber(null);          // Reset Kua number
  };

  const handleSubmit = () => {
    const { loShuGrid, kuaNumber } = calculateLoShuGrid(dob, gender);
    setGrid(loShuGrid);
    setKuaNumber(kuaNumber);
  };

  return (
    <Base>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: '20px',
        maxWidth: '400px', // Set a maximum width for the content
        margin: '0 auto',  // Center the content horizontally
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
      }}>
        <h1>Lo Shu Grid Calculator</h1>
        <label style={{ marginBottom: '10px', width: '100%' }}>
          Date of Birth:
          <input 
            type="date" 
            value={dob} 
            onChange={handleChangeDOB} 
            style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginBottom: '20px', width: '100%' }}>
          Gender:
          <select 
            value={gender} 
            onChange={handleChangeGender}
            style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </label>
        <button 
          onClick={handleSubmit}
          style={{
            width: '100%', 
            padding: '10px', 
            borderRadius: '5px', 
            backgroundColor: '#4CAF50', 
            color: '#fff', 
            fontWeight: 'bold', 
            border: 'none', 
            cursor: 'pointer',
            marginBottom: '20px'
          }}
        >
          Calculate Lo Shu Grid
        </button>
        <LoShuGrid grid={grid} />
      </div>
    </Base>
  );
};

export default LoShuGridChart;
