import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../../services/helper";
import {loginUser} from '../../services/user-service'

const LOGIN_URL = "login";
const initialState = {
  user: {
    // susertoken: "",
    // uid: "",
    // uname: "",
    // actid: "",
    // email: "",
    // request_time: "",
  },
  status: STATUSES.LOADING,
};

export const getloginUser = createAsyncThunk("user/auth", async (loginDetail) => {
  console.log("Asy",loginDetail)
  const response = await loginUser(loginDetail);
  return response;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserSession: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getloginUser.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(getloginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(getloginUser.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});

export const { getUserSession } = userSlice.actions;

export default userSlice.reducer;
