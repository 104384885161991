import CustomNavBar from "./CustomNavBar";
import Footer from "./Footer";

const Base = ({ title = 'Welcome to StackLearn', children }) => {
    return (
        <div className="fluid-container">
            <CustomNavBar/>
            {children}
            {/* <Footer/> */}
        </div>
    );
};

export default Base;