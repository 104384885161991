import React, { useState, useContext, useRef } from "react";
import FinContext from "../../context/FinContext";
import {addfin,getfin} from "../../auth/index";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';

function IncomeForm() {

  const {income,setincome} = useContext(FinContext);

  const [data , setData] = useState({
    desc : "",
    price : "",
    date : ""
  });

  const handleChange = (event, fieldname) => {
    setData({ ...data, [fieldname]: event.target.value });
  };

  const resetData = () => {
    setData({
      desc : "",
      price : "",
      date : ""
    });
  };

  const AddIncome = (e) => {
    e.preventDefault();

    if (data.desc.trim() == "" || data.price.trim() == "" ) {
      toast.error("User Input is required !!");
      return;
    }
    console.log('data date ', data.date)

    let d = data.date.split("-");
    let newD = new Date(d[0], d[1] - 1, d[2]);
    data.date = newD;

    console.log('data date ', newD)

    addfin(data);
    var datamodal = [];
    datamodal = getfin();
    setincome(datamodal)
    resetData();
  };

  return (
    <form className="income-form" onSubmit={AddIncome}>
      <div className="form-inner">
        <input
          type="text"
          name="desc"
          id="desc"
          placeholder="Income Description..."
          onChange={(e) => handleChange(e, "desc")}
          value={data.desc}
          
        />
        <input
          type="number"
          name="price"
          id="price"
          placeholder="Price..."
          onChange={(e) => handleChange(e, "price")}
          value={data.price}
        />
        <input
          type="date"
          name="date"
          id="date"
          placeholder="Income date..."
          onChange={(e) => handleChange(e, "date")}
          value={data.date}
        />
        <input type="submit" value="Add Income" />
      </div>
    </form>
  );
}

export default IncomeForm;
