import Base from "../components/Base";
import './dashboard.css';

const Dashboard = () => {
  return (
    <Base>
        <h1>This is Dashboard Page</h1>
    <div className="container1">
      <button className="btn">Start</button>
      <button className="btn">Process</button>
      <button className="btn">Continue</button>
      <button className="btn">Pause</button>
      <button className="btn">Stop</button>
      <button className="btn">End</button>
    </div>
    </Base>
  );
};

export default Dashboard;
