import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Base from "../components/Base";
import {signUp} from "../services/user-service"

const SignUp = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    password: "",
    about: "",
  });
  const [error, setError] = useState({
    error: {},
    hasError: false,
  });

  const handleChange = (event, fieldname) => {
    setData({ ...data, [fieldname]: event.target.value });
  };

  const resetData = () => {
    setData({
      firstName: "",
      lastName: "",
      email: "",
      mobileNo: "",
      password: "",
      about: "",
    });
  };

  const submitForm = (event)=>{
    event.preventDefault();
    console.log(data);
    signUp(data).then((resp)=>{
      console.log(resp);

    }).catch((error)=>{
      console.log(error);
    })
    //Validation
    //Call apis
  }

  //The useEffect Hook allows you to perform side effects in your components : fetching data, directly updating the DOM, and timers.
  //   useEffect(()=>{
  //     console.log('useEffect' + data.name);
  //   },[data] //Runs only on the first render if array is defined
  //   )

  return (
    <Base>
      <Container>
        <Row className="mt-4">
          <Col sm={{ size: 6, offset: 3 }}>
            <Card color="dark" outline>
              <CardHeader>
                <h3>SignUp Here !!</h3>
              </CardHeader>
              <CardBody>
                <Form onSubmit={submitForm}>
                  <FormGroup>
                    <label for="name">Enter First Name</label>
                    <Input
                      type="text"
                      placeholder="Enter First Name here"
                      id="firstName"
                      onChange={(e) => handleChange(e, "firstName")}
                      value={data.firstName}
                    >
                      First Name
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for="name">Enter Last Name</label>
                    <Input
                      type="text"
                      placeholder="Enter Last Name here"
                      id="lastName"
                      onChange={(e) => handleChange(e, "lastName")}
                      value={data.lastName}
                    >
                     Last Name
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for="email">Enter Email</label>
                    <Input
                      type="text"
                      placeholder="Enter Email here"
                      id="email"
                      onChange={(e) => handleChange(e, "email")}
                      value={data.email}
                    >
                      Email
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for="email">Enter Mobile</label>
                    <Input
                      type="text"
                      placeholder="Enter Mobile here"
                      id="mobileNo"
                      onChange={(e) => handleChange(e, "mobileNo")}
                      value={data.mobileNo}
                    >
                      Mobile
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for="password">Enter Password</label>
                    <Input
                      type="password"
                      placeholder="Enter Password here"
                      id="password"
                      onChange={(e) => handleChange(e, "password")}
                      value={data.password}
                    >
                      Password
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <label for="about">Enter About</label>
                    <Input
                      type="textarea"
                      placeholder="Enter about here"
                      id="about"
                      onChange={(e) => handleChange(e, "about")}
                      value={data.about}
                    >
                      About
                    </Input>
                  </FormGroup>
                  <Container className="text-center">
                    <Button color="primary" outline>
                      Register
                    </Button>
                    <Button
                      color="secondary"
                      className="ms-2"
                      outline
                      onClick={resetData}
                    >
                      Reset
                    </Button>
                  </Container>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Base>
  );
};

export default SignUp;
