import React, { useState } from "react";
import NumerologyInfoModal from "./NumerologyInfoModal";
import "./dashboard.css";
import Base from "../components/Base";

// Chaldean Numerology Chart
const chaldeanNumerologyChart = {
  A: 1,
  I: 1,
  J: 1,
  Q: 1,
  Y: 1,
  B: 2,
  K: 2,
  R: 2,
  C: 3,
  G: 3,
  L: 3,
  S: 3,
  D: 4,
  M: 4,
  T: 4,
  E: 5,
  H: 5,
  N: 5,
  X: 5,
  U: 6,
  V: 6,
  W: 6,
  O: 7,
  Z: 7,
  F: 8,
  P: 8,
};

const calculateNumerology = (name) => {
  let calculations = [];
  let total = 0;
  if (name !== "") {
    for (let char of name.toUpperCase()) {
      if (chaldeanNumerologyChart[char]) {
        const value = chaldeanNumerologyChart[char];
        calculations.push({ char, value });
        total += value;
      } else if (!isNaN(char) && char !== " ") {
        const value = parseInt(char, 10);
        calculations.push({ char, value });
        total += value;
      } else if (char === " ") {
        calculations.push({ char, value: null });
      }
    }

    let reducedTotal = total;
    while (reducedTotal > 9) {
      reducedTotal = String(reducedTotal)
        .split("")
        .reduce((acc, num) => acc + parseInt(num, 10), 0);
    }

    return { total, reducedTotal, calculations };
  }
};

const getSingleDigitSum = (number) => {
  let sum = number;

  // Continue summing the digits until the sum is a single digit
  while (sum > 9) {
    sum = sum
      .toString()
      .split("")
      .map(Number)
      .reduce((acc, num) => acc + num, 0);
  }

  return sum;
};

const NumerologyCalculator = () => {
  const [name, setName] = useState("");
  const [numerologyValue, setNumerologyValue] = useState(null);
  const [calculationSteps, setCalculationSteps] = useState([]);

  const handleCalculate = () => {
    const { total, reducedTotal, calculations } = calculateNumerology(name);

    // Split calculations into arrays separated by spaces
    const splitCalculations = [];
    let currentArray = [];

    calculations.forEach((step) => {
      if (step.char === " ") {
        if (currentArray.length > 0) {
          splitCalculations.push(currentArray);
        }
        currentArray = [];
      } else {
        currentArray.push(step);
      }
    });

    // Push the last array if it has any items
    if (currentArray.length > 0) {
      splitCalculations.push(currentArray);
    }

    setNumerologyValue(reducedTotal);
    setCalculationSteps(splitCalculations);
  };

  return (
    <Base>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          maxWidth: "650px",
          margin: "0 auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}
      >
        <h1
          style={{
            fontFamily: "Arial, sans-serif",
            marginBottom: "20px",
            textAlign: "center",
            fontSize: "1.8em",
          }}
        >
          𝐃𝐢𝐬𝐜𝐨𝐯𝐞𝐫𝐢𝐧𝐠 𝐂𝐡𝐚𝐥𝐝𝐞𝐚𝐧 𝐍𝐮𝐦𝐞𝐫𝐨𝐥𝐨𝐠𝐲
        </h1>

        <div style={{ marginBottom: "20px" }}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Your Name"
            style={{
              padding: "10px",
              fontSize: "16px",
              width: "100%",
              maxWidth: "400px",
              border: "1px solid #4B0082",
              borderRadius: "5px",
              marginTop: "10px",
              boxSizing: "border-box",
            }}
          />
        </div>

        <button
          onClick={handleCalculate}
          style={{
            padding: "10px 20px",
            fontSize: "16px",
            color: "white",
            backgroundColor: "cadetblue",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
            maxWidth: "200px",
          }}
        >
          Calculate
        </button>

        {numerologyValue !== null && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <h2>Calculation Steps:</h2>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
                overflowX: "auto",
              }}
            >
              {calculationSteps.map((stepsArray, index) => {
                // Calculate the total value of the current stepsArray
                const tableTotal = stepsArray.reduce(
                  (acc, step) => acc + (step.value !== null ? step.value : 0),
                  0
                );

                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <table
                      style={{
                        border: "1px solid black",
                        borderCollapse: "collapse",
                        margin: "10px 0",
                      }}
                    >
                      <tbody>
                        <tr>
                          {stepsArray.map((step, idx) => (
                            <td
                              key={idx}
                              style={{
                                border: "1px solid black",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              {step.char === " " ? (
                                <span>&nbsp;</span>
                              ) : (
                                step.char
                              )}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          {stepsArray.map((step, idx) => (
                            <td
                              key={idx}
                              style={{
                                border: "1px solid black",
                                padding: "10px",
                                textAlign: "center",
                              }}
                            >
                              {step.value !== null ? (
                                step.value
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>

                    {/* Display the total for the current table */}
                    <div
                      style={{
                        marginLeft: "20px",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      = {getSingleDigitSum(tableTotal)}
                    </div>
                  </div>
                );
              })}
            </div>

            <p>
              Sum of values ={" "}
              {calculationSteps
                .flatMap((array) => array)
                .filter((step) => step.value !== null)
                .map((step) => step.value)
                .join(" + ")}{" "}
              ={" "}
              {calculationSteps
                .flatMap((array) => array)
                .filter((step) => step.value !== null)
                .reduce((acc, step) => acc + step.value, 0)}
            </p>

            <p>
              Single digit ={" "}
              {String(
                calculationSteps
                  .flatMap((array) => array)
                  .filter((step) => step.value !== null)
                  .reduce((acc, step) => acc + step.value, 0)
              )
                .split("")
                .join(" + ")}{" "}
              = {numerologyValue}
            </p>

            <h2 style={{ fontFamily: "Arial, sans-serif" }}>
              𝐂𝐡𝐚𝐥𝐝𝐞𝐚𝐧 Destiny Number: {numerologyValue}{" "}
              <NumerologyInfoModal></NumerologyInfoModal>
            </h2>
          </div>
        )}
      </div>
    </Base>
  );
};

export default NumerologyCalculator;
