import Base from "../components/Base";

const About = () => {
  return (
    <Base>
      <h1>This is About page</h1>
    </Base>
  );
};

export default About;
