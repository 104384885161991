import IncomeItem from './IncomeItem';
import React, { useContext } from "react";
import FinContext from '../../context/FinContext';
import {updatefin,ischeckString} from "../../auth/index";

function IncomeList() {

  const {income,setincome} = useContext(FinContext)

  const removeIncome = i => {
    let temp = income.filter((v, index) => index != i);
    // console.log(temp);
    updatefin(temp);
    setincome(temp);
  }

  function sortincome(...income){ 
    console.log("sort" , income);
    return income.sort(function(a,b){ 
      return a.date - b.date;
    }); 
 }

 //   You can use ES6 syntax, and play with .reverse() to get ASC or DESC
// value.sort((a, b) => new Date(b.date) - new Date(a.date)).reverse()
 const sortByDate = (a, b) => {
  a = ischeckString(a) ? JSON.parse(a) : a;
  b = ischeckString(b) ? JSON.parse(b) : b;
  return new Date(a.date) - new Date(b.date);
}

  return (
    <div className="income-list">
      {
       income && income.sort(sortByDate).map((value, index) => (
            <IncomeItem 
              key={index} 
              incomedata ={value} 
              index={index} 
              removeIncome={removeIncome}
            />
          ))        
          }
    </div>
  )
}

export default IncomeList;