import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "../features/User/userSlice";
import filterReducer from "../features/filter/filterSclice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    filter: filterReducer,
    // websocket : websocketReducer,
    //[websocketRTKSlice.reducerPath]: websocketRTKSlice.reducer,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(websocketRTKSlice.middleware);
  // },
});
